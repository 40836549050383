import React, { Component } from 'react';
import { Badge } from 'reactstrap';

// Search component
export default class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            results: [],
            ready: false
        }
    }

    componentDidMount() {
        /** 
         * LUNR index gets injected in Window object however
         * it is not set when this function first runs. We will
         * continuously check until we find it.
         **/
        let job = setInterval(() => {
            if( window.hasOwnProperty('__LUNR__')) {
                this.setState({
                    results: this.getSearchResults(this.props.query),
                    ready: true
                })
                clearInterval(job);
            }
        }, 100);
    }

    render() {
        return (
            <div>
                <h2>Search for <Badge color="secondary">{ this.props.query.replace(/\+/g, '') }</Badge></h2>
                { this.state.ready ?
                    <div>
                        <h3>We found { this.state.results.length } results.</h3> 
                        <ul>
                            { this.state.results.map( (node, index) => 
                                <li key={ index }>
                                    <a href={ node.url }>{node.title}</a><br />
                                    <p>{ node.display_content } 
                                        <a href={ node.url } title="Match">View</a>
                                    </p>
                                </li>)
                            }
                        </ul>
                    </div> :
                    <div className="text-center">
                        <span className="fas fa-spinner fa-spin fa-lg fa-5x"></span>
                    </div>
                }
            </div>
        )
    }

    getSearchResults(query) {
        if (!query || !window.__LUNR__) return []
        const lunrIndex =  window.__LUNR__['en'];
        const results = lunrIndex.index.search(query) // you can  customize your search , see https://lunrjs.com/guides/searching.html
        return results.map(({ ref }) => lunrIndex.store[ref])
    }
}