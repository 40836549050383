import React from 'react';
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import { Container, Row, Col } from "reactstrap";
import SearchResults from "../components/SearchResults";

class SearchPage extends React.Component {

    render() {
        if (!this.props.location.search) {
            if( typeof window !== `undefined` ) window.location = "/";
            return null;
        }

        const searchParam = this.props.location.search;
        // Remove +'s (we will prefix terms with them afterward)
        const q = decodeURI(searchParam.match(/q=([^&]*)+/)[1]);        
        const sanitized_q = q.replace(/[^A-Za-z0-9 ]/g,'');
        
        // we need at least 1 regular alpha-numeric char to search for
        const q_length = sanitized_q.length;
        
        // all search terms get prefixed by '+' ensuring they exist in the corpus
        const inclusive_q = sanitized_q.split(' ').map(token => "+" + token).join(' ');
        
        return (
            <Layout>
                <Helmet>      
                    <meta http-equiv="Cache-Control" content="public, max-age=0, must-revalidate" />
                    <meta http-equiv="Pragma" content="no-cache"/>
                </Helmet>
                <Container>
                    <Row>
                        <Col>
                            {
                                q_length > 0 ?
                                    <SearchResults query={ inclusive_q }>
                                    </SearchResults> 
                                    : 
                                    <h2>Please try searching again.</h2>
                            }                        
                        </Col>
                    </Row>
                </Container>
            </Layout>
        );
    }
}

export default SearchPage